import React from "react"
import PartnerForm from "../../components/forms/partner"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const JoinPartnerPage = () => (
  <Layout>
    <SEO title="Work With Flapmax" description="" lang="en" meta={[]} />

    <section className="full-hero join">
      <div className="text-block text-center mb-3">
        <h1 className="">Partner with Flapmax</h1>
        <h3>
          We are excited about your interest and appreciate you reaching out to
          us.
        </h3>
        <h3>
          Please feel free to tell us a little about yourself and what you are
          looking for in a partnership.
        </h3>
      </div>

      <PartnerForm />
    </section>
  </Layout>
)

export default JoinPartnerPage
